<template>
  <div id="div-tabela-kpis-by-municipio">
    <v-text-field
      v-if="numMunicipios > 10"
      v-model="search"
      append-icon="mdi-magnify"
      class="ml-auto mb-5 break-search"
      label="Procurar"
      hide-details
      single-line
    />
    <v-data-table
      class="data-table-kpis"
      multi-sort
      dense
      :headers="headers"
      :items="items"
      :search.sync="search"
      :sort-by="['qlp']"
      :sort-desc="['qlp']"
      :options="{
        itemsPerPage: 10
      }"
      :headerProps="{
        sortByText: 'Ordenar Por'
      }"
      :hide-default-footer="numMunicipios <= 10"
      no-data-text="Nenhum registro disponível"
      no-results-text="Nenhum registro correspondente encontrado"
      :mobile-breakpoint="10"
    >
    <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
        <v-tooltip bottom v-if="traducaoIndicadores[h.value] != null">
          <template v-slot:activator="{ on }">
            <span v-on="on">{{h.text}}</span>
          </template>
          <span>{{traducaoIndicadores[h.value]}}</span>
        </v-tooltip>
        <span v-else>{{h.text}}</span>
    </template>

      <template v-slot:[`item.qlp`]="{ item }">
        {{ item.qlp | parseNumberToFloatBrIgnoringNaN }}
      </template>
      <template v-slot:[`item.pla`]="{ item }">
        {{ item.pla | parseNumberToFloatBrIgnoringNaN }}%
      </template>
      <template v-slot:[`item.pla+e`]="{ item }">
        {{ item["pla+e"] | parseNumberToFloatBrIgnoringNaN }}%
      </template>
      <template v-slot:[`item.pmea`]="{ item }">
        {{ item.pmea | parseNumberToFloatBrIgnoringNaN }}
      </template>
      <template v-slot:[`item.pmea2`]="{ item }">
        {{ item.pmea2 | parseNumberToFloatBrIgnoringNaN }}
      </template>
      <template v-slot:[`item.pat1`]="{ item }">
        {{ item.pat1 | parseNumberToFloatBrIgnoringNaN }}
      </template>
      <template v-slot:[`item.pat2`]="{ item }">
        {{ item.pat2 | parseNumberToFloatBrIgnoringNaN }}
      </template>
      <template v-slot:[`item.pat3`]="{ item }">
        {{ item.pat3 | parseNumberToFloatBrIgnoringNaN }}
      </template>
      <template v-slot:[`item.pat4`]="{ item }">
        {{ item.pat4 | parseNumberToFloatBrIgnoringNaN }}
      </template>
      <template v-slot:[`item.ilp`]="{ item }">
        {{ item.ilp | parseNumberToFloatBrIgnoringNaN }}
      </template>
    </v-data-table>
  </div>
</template>

<script>

export default {
  name: 'KpisByMunicipioDataTable',
  props: {
    dadosKpisByMunicipio: {
      type: Object,
      required: true  
    },
    traducaoIndicadores: {
      type: Object,
      default: {}
    }
  },
  data: () => ({
    search: ''
  }),
  computed: {
    kpis() {
      return Object.keys(this.dadosKpisByMunicipio);
    },
    headers() {
      let headers = [
        {
          text: 'IBGE',
          value: 'codigo_ibge'
        },
        {
          text: 'Município',
          value: 'nome_municipio',
          width: '100px'
        }
      ];
      this.kpis.forEach(kpi => headers.push({
        text: kpi.toUpperCase(),
        value: kpi.toLowerCase()
      }));
      return headers;
    },
    dadosKpisIndexedByMunicipio() {
      let indexedBy = {};
      this.kpis.forEach(kpi => {
        indexedBy[kpi] = {};
        Object.values(this.dadosKpisByMunicipio[kpi]).forEach(dado => indexedBy[kpi][dado['codigo_ibge']] = dado);
      });
      return indexedBy;
    },
    todosMunicipios() {
      let todosMunicipios = {};
      this.kpis.forEach(kpi => Object.values(this.dadosKpisByMunicipio[kpi]).forEach(dado => todosMunicipios[dado['codigo_ibge']] = dado['nome_municipio']));
      return todosMunicipios;
    },
    numMunicipios() {
      return Object.keys(this.todosMunicipios).length;
    },
    items() {
      let items = [];
      Object.keys(this.todosMunicipios).forEach(codigoIbge => {
        let item = {
          'codigo_ibge': codigoIbge,
          'nome_municipio': this.todosMunicipios[codigoIbge]
        };
        this.kpis.forEach(kpi => {
          const valor = 
            this.dadosKpisIndexedByMunicipio && 
            this.dadosKpisIndexedByMunicipio[kpi] && 
            this.dadosKpisIndexedByMunicipio[kpi][codigoIbge] && 
            this.dadosKpisIndexedByMunicipio[kpi][codigoIbge]['valor'] 
            ? this.dadosKpisIndexedByMunicipio[kpi][codigoIbge]['valor'] 
            : 0; 
          item[kpi] = Number(valor);
        });
        items.push(item);
      });
      return items;
    }
  }
}

</script>

<style>

#div-tabela-kpis-by-municipio {
  min-height: 420px;
}
.data-table-kpis table tbody tr td {
  font-size: 12px;
  padding: 0px 8px !important;
}
.data-table-kpis table thead tr th {
  font-size: 12px;
  height: 25px;
  font-weight: 400;
}

</style>
